exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onze-diensten-tsx": () => import("./../../../src/pages/onze-diensten.tsx" /* webpackChunkName: "component---src-pages-onze-diensten-tsx" */),
  "component---src-pages-onze-diensten-wp-page-slug-tsx": () => import("./../../../src/pages/onze-diensten/{wpPage.slug}.tsx" /* webpackChunkName: "component---src-pages-onze-diensten-wp-page-slug-tsx" */),
  "component---src-pages-portfolio-post-wp-post-slug-tsx": () => import("./../../../src/pages/portfolio/post/{wpPost.slug}.tsx" /* webpackChunkName: "component---src-pages-portfolio-post-wp-post-slug-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-category-posts-tsx": () => import("./../../../src/templates/categoryPosts.tsx" /* webpackChunkName: "component---src-templates-category-posts-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

